import styled from 'styled-components'
import useUpload from './useUpload'

const Form = styled.form`
  padding: 20px;
  box-sizing: content-box;
`

const InputWrapper = styled.div`
  padding-bottom: 30px;
  font-family: 'Gilroy';
  font-weight: lighter;
`

const Label = styled.div`
  margin-bottom: 10px;
`

const Input = styled.input`
  height: 40px;
  width: 100%;
`

const FlexContainer = styled.div`
  display: flex;
`

const Upload = () => {
  const { album, addReward, editAlbum, editReward, removeReward, onSubmit } =
    useUpload()

  return (
    <Form onSubmit={onSubmit}>
      <InputWrapper>
        <Label>nom de l'album</Label>
        <Input name="name" onChange={editAlbum} />
      </InputWrapper>
      <InputWrapper>
        <Label>nom de l'artiste</Label>
        <Input name="artist" onChange={editAlbum} />
      </InputWrapper>
      <InputWrapper>
        <Label>lien de l'image</Label>
        <Input name="imageLink" onChange={editAlbum} />
      </InputWrapper>

      <InputWrapper>
        <Label>
          Récompenses{' '}
          <button
            type="button"
            onClick={() => {
              addReward()
            }}
          >
            Ajouter
          </button>
        </Label>
        {album.rewards.map((reward, index) => (
          <FlexContainer>
            <Input
              name="imageLink"
              value={reward}
              onChange={(e) => editReward(index, e.target.value)}
            />
            <button
              type="button"
              onClick={() => {
                removeReward(index)
              }}
            >
              supprimer
            </button>
          </FlexContainer>
        ))}
      </InputWrapper>
      <button type="submit">Valider le formulaire</button>
    </Form>
  )
}

export default Upload
