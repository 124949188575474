import { useState } from 'react'
import axios from 'axios'

const useUpload = () => {
  const [album, setAlbum] = useState({
    name: '',
    artist: '',
    imageLink: '',
    rewards: [],
  })

  const addReward = () => {
    let rewardsCopy = [...album.rewards, '']
    setAlbum({ ...album, rewards: rewardsCopy })
  }

  const editAlbum = (event) => {
    const albumCopy = { ...album }
    albumCopy[event.target.name] = event.target.value
    return setAlbum(albumCopy)
  }

  const editReward = (rewardIndex, newReward) => {
    let rewardsCopy = [...album.rewards]
    rewardsCopy[rewardIndex] = newReward
    return setAlbum({ ...album, rewards: rewardsCopy })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    axios
      .post(
        'https://djikvznzvuk55svglqlh74bgg40azxys.lambda-url.eu-north-1.on.aws',
        { album: album }
      )
      .then((response) => {
        console.log(response)
      })
      .catch((err) => console.log(err))
  }

  const removeReward = (rewardIndex) => {
    let rewardsCopy = [...album.rewards]
    rewardsCopy.splice(rewardIndex, 1)
    setAlbum({ ...album, rewards: rewardsCopy })
  }

  return { album, addReward, editAlbum, editReward, onSubmit, removeReward }
}

export default useUpload
