import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'

const useAlbums = () => {
  const [albums, setAlbums] = useState([])
  const [loading, setLoading] = useState(true)

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }

    return array
  }

  useEffect(() => {
    axios
      .get(
        'https://lfqtpnkknymzcoon7p7guuqudm0hkhgq.lambda-url.eu-north-1.on.aws/'
      )
      .then((response) => {
        setAlbums(shuffle(response.data))
        setLoading(false)
      })
  }, [])

  const [albumIndex, setAlbumIndex] = useState(0)
  const [showRewards, setShowRewards] = useState(false)
  const [isChanging, setIsChanging] = useState(false)

  const changeAlbum = (newIndex) => {
    if (!isChanging) {
      setIsChanging(true)
      const changeAlbumTimeout = setTimeout(() => {
        setIsChanging(false)
        setAlbumIndex(newIndex)
        clearTimeout(changeAlbumTimeout)
      }, 500)
    }
  }

  return {
    albums,
    albumIndex,
    changeAlbum,
    currentAlbum: { ...albums[albumIndex] },
    isChanging,
    loading,
    setShowRewards,
    showRewards,
  }
}

export default useAlbums
