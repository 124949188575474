import styled from 'styled-components'
import ArrowIcon from '../../../../assets/images/icons/arrow.svg'

const Wrapper = styled.div`
  width: 900px;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  position: relative;
`

const Jacket = styled.div`
  background: ${({ image }) =>
    `url('${image}')`};
  background-size: cover;
  background-position: center;
  transition: 0.3s transform;
  cursor: pointer;
  border-radius: 20px;
`

const CurrentJacket = styled(Jacket)`
  width: 250px;
  height: 250px;
  z-index: 3;
  &:hover {
    transform: scale(1.1);
  }
`

const PreviousJacket = styled(Jacket)`
  filter: ${({ blur }) => `blur(${blur}px)`};
  position: absolute;
  top: 50%;
  transform: ${({ translate }) => `translate(${translate}, -50%)`};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  z-index: ${({ index }) => index};
  left: 50%;
  &:hover {
    transform: ${({ translate }) => `translate(${translate}, -50%) scale(1.1)`};
  }
`

const NextJacket = styled(Jacket)`
  filter: ${({ blur }) => `blur(${blur}px)`};
  position: absolute;
  top: 50%;
  transform: ${({ translate }) => `translate(${translate}, -50%)`};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  z-index: ${({ index }) => index};
  right: 50%;
  &:hover {
    transform: ${({ translate }) => `translate(${translate}, -50%) scale(1.1)`};
  }
`

const Arrow = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  background: ${() => `url('${ArrowIcon}')`};
  background-size: cover;
  cursor: pointer;
  transition: 0.3s;
  top: 50%;

  &:hover {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 1060px) {
    display: none;
  }
`

const ArrowLeft = styled(Arrow)`
  left: 0;
  transform: translate(-100%, -50%);
`

const ArrowRight = styled(Arrow)`
  right: 0;
  transform: translate(100%, -50%) rotate(180deg);
`

const Jackets = ({ albums, albumIndex, changeAlbum }) => {
  const getJacketsIndexes = () => {
    if (albumIndex === 0) {
      return [albums.length - 2, albums.length - 1, albumIndex, 1, 2]
    } else if (albumIndex === 1) {
      return [albums.length - 1, 0, albumIndex, 2, 3]
    } else if (albumIndex === albums.length - 1) {
      return [albumIndex - 2, albumIndex - 1, albumIndex, 0, 1]
    } else if (albumIndex === albums.length - 2) {
      return [albumIndex - 2, albumIndex - 1, albumIndex, albumIndex + 1, 0]
    } else {
      return [
        albumIndex - 2,
        albumIndex - 1,
        albumIndex,
        albumIndex + 1,
        albumIndex + 2,
      ]
    }
  }

  return (
    <Wrapper>
      <ArrowLeft
        onClick={() => {
          changeAlbum(getJacketsIndexes()[1])
        }}
      />
      <PreviousJacket
        blur={'2'}
        size={'150'}
        index={1}
        translate={'-250%'}
        image={albums[getJacketsIndexes()[0]].imageLink || ""}
        onClick={() => {
          changeAlbum(getJacketsIndexes()[0])
        }}
      />
      <PreviousJacket
        blur={'1'}
        size={'200'}
        index={2}
        translate={'-130%'}
        image={albums[getJacketsIndexes()[1]].imageLink || ""}
        onClick={() => {
          changeAlbum(getJacketsIndexes()[1])
        }}
      />
      <CurrentJacket image={albums[getJacketsIndexes()[2]].imageLink || ""} />
      <NextJacket
        blur={'1'}
        size={'200'}
        index={2}
        translate={'130%'}
        image={albums[getJacketsIndexes()[3]].imageLink || ""}
        onClick={() => {
          changeAlbum(getJacketsIndexes()[3])
        }}
      />
      <NextJacket
        blur={'2'}
        size={'150'}
        index={1}
        translate={'250%'}
        image={albums[getJacketsIndexes()[4]].imageLink || ""}
        onClick={() => {
          changeAlbum(getJacketsIndexes()[4])
        }}
      />

      <ArrowRight
        onClick={() => {
          changeAlbum(getJacketsIndexes()[3])
        }}
      />
    </Wrapper>
  )
}

export default Jackets
