import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Albums from './components/Albums'
import Upload from './components/Upload'

const router = createBrowserRouter([
  {
    path: '*',
    element: <Albums />,
  },
  {
    path: '/albums/new',
    element: <Upload/>,
  },
])

function App() {
  return <RouterProvider router={router} />
}

export default App