import { useSwipeable } from 'react-swipeable'
import useAlbums from '../../useAlbums'
import styled from 'styled-components'
import Album from '../Album'
import EmailIcon from '../../assets/images/icons/mail.svg'
import DiscogsIcon from '../../assets/images/icons/discogs.svg'
import InstagramIcon from '../../assets/images/icons/instagram.svg'

const Wrapper = styled.div`
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // Use to set the dynamic height to 100vh, but remove the chrome and safari search bar
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`

const Name = styled.a`
  position: fixed;
  font-family: 'DM Sans', sans-serif;
  letter-spacing: -1.5px;
  font-weight: 400;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 17px;
  cursor: pointer;
  text-decoration: none;
`

const Logos = styled.div`
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  height: 25px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const Discogs = styled.a`
  height: 25px;
  width: 25px;
  background-image: ${() => `url('${DiscogsIcon}')`};
  cursor: pointer;
  background-size: cover;
  background-position: center;
`

const Instagram = styled.a`
  height: 25px;
  width: 25px;
  background-image: ${() => `url('${InstagramIcon}')`};
  background-size: cover;
  background-position: center;
  cursor: pointer;
`

const Email = styled.button`
  position: fixed;
  top: 10px;
  right: 5px;
  background: transparent;
  padding: 5px 10px;
  border: 2px solid white;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  color: white;
  font-family: 'inter';
  font-weight: 800;
  font-size: 11px;
  letter-spacing: -0.5px;
  cursor: pointer;
`

const AlbumBackground = styled.div`
  top: 0;
  left: 0;
  height: 60vh;
  width: 100%;
  position: absolute;
  background: ${({ albumImage }) => `url('${albumImage}')`};
  background-size: cover;
  background-position: center;

  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.4);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: linear-gradient(0deg, #000000 40.62%, rgba(0, 0, 0, 0.11) 100%);
  }
`

const Loading = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  color: white;
  font-family: 'gilroy';
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    font-size: 35px;
  }
`

const Albums = () => {
  const {
    albums,
    albumIndex,
    changeAlbum,
    currentAlbum,
    isChanging,
    loading,
    showRewards,
    setShowRewards,
  } = useAlbums()

  const handlers = useSwipeable({
    onSwiped: ({ dir }) => {
      if (dir === 'Right')
        changeAlbum(albumIndex === 0 ? albums.length - 1 : albumIndex - 1)
      if (dir === 'Left')
        changeAlbum(albumIndex === albums.length - 1 ? 0 : albumIndex + 1)
    },
    delta: 100, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  })

  return (
    <>
      {loading ? (
        <Loading>Chargement...</Loading>
      ) : (
        <Wrapper {...handlers}>
          <Name href="mailto:jeffginouves@gmail.com">Jeff Ginouvès</Name>
          <Logos>
            <Discogs
              href="https://www.discogs.com/fr/artist/572694-Jeff-Ginouv%C3%A8s"
              target="_blank"
            />
            <Instagram
              href="https://www.instagram.com/jeffginouves/"
              target="_blank"
            />
          </Logos>
          {/* <Email href="mailto:jeff.ginouves@gmail.com">Contacter</Email> */}
          <AlbumBackground albumImage={currentAlbum.imageLink} />
          <Album
            albumIndex={albumIndex}
            albums={albums}
            changeAlbum={changeAlbum}
            currentAlbum={currentAlbum}
            isChanging={isChanging}
            showRewards={showRewards}
            setShowRewards={setShowRewards}
          />
        </Wrapper>
      )}
    </>
  )
}

export default Albums
