import styled from 'styled-components'

import Jackets from './components/Jackets'
import RewardIcon from '../../assets/images/icons/reward.svg'
import CrossIcon from '../../assets/images/icons/cross.svg'

const Wrapper = styled.div`
  width: 100%;
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-height: 700px) {
    margin-bottom: 50px;
  }
`

const AlbumIndex = styled.div`
  font-size: 11px;
  letter-spacing: 3px;
  font-family: 'inter';
  font-weight: 800;
  margin-bottom: 10px;
`

const AlbumName = styled.h1`
  font-family: 'Gilroy';
  font-weight: 800;
  font-size: 40px;
  margin: 10px 0;
  text-align: center;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s left;
    left: ${({ isChanging }) => (isChanging ? '0' : '100%')};
    top: 0;
    background-color: black;
  }
`

const Artist = styled.p`
  font-family: 'inter';
  font-weight: 800;
  font-size: 11px;
  margin: 0;
  letter-spacing: 6px;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s left;
    left: ${({ isChanging }) => (isChanging ? '0' : '100%')};
    top: 0;
    background-color: black;
  }
`

const RewardsButton = styled.button`
  font-family: 'Gilroy';
  font-weight: 800;
  font-size: 17px;
  margin: 25px 0 0 0;
  background: transparent;
  border: 1px solid white;
  border-radius: 100px;
  color: white;
  padding: 15px 50px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transform: scale(1.1);
  }
`

const Rewards = styled.div`
  position: fixed;
  bottom: ${({ show }) =>
    show ? document.documentElement.style.getPropertyValue('--vh') : '-150vh'};
  overflow-y: scroll;
  overflow-x: hidden;
  left: 0;
  width: 100%;
  border-top-left-radius: 46px;
  border-top-right-radius: 46px;
  height: 80vh;
  background: black;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  z-index: 5;
  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 4px;
    border-radius: 10px;
    background-color: #3a3a3a;
  }
`

const Cross = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  width: 20px;
  height: 20px;
  background-image: ${() => `url('${CrossIcon}')`};
  background-size: cover;
  cursor: pointer;
`

const RewardsAlbumName = styled(AlbumName)`
  font-size: 30px;
  margin: 40px 0 20px 0;
`

const RewardsContainer = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  max-width: 300px;
`

const Reward = styled.div`
  font-family: 'inter';
  font-weight: 800;
  font-size: 15px;
  margin: 0;
  position: relative;
  margin-top: 20px;
  line-height: 29px;
  padding-left: 30px;
  &::before {
    content: '';
    left: 0;
    top: 50%;
    position: absolute;
    width: 15px;
    height: 15px;
    transform: translate(0, -50%);
    background-image: ${() => `url('${RewardIcon}')`};
    background-size: cover;
  }
`

const Album = ({
  albums,
  albumIndex,
  changeAlbum,
  currentAlbum,
  isChanging,
  showRewards,
  setShowRewards,
}) => {
  return (
    <Wrapper>
      <AlbumIndex>
        {albumIndex + 1} / {albums.length}
      </AlbumIndex>
      <Jackets
        albums={albums}
        albumIndex={albumIndex}
        changeAlbum={changeAlbum}
        currentAlbum={currentAlbum}
      />
      <AlbumName isChanging={isChanging}>{currentAlbum.name}</AlbumName>
      <Artist isChanging={isChanging}>
        {currentAlbum.artist.toUpperCase()}
      </Artist>
      <RewardsButton
        onClick={() => {
          setShowRewards(true)
        }}
      >
        Récompenses
      </RewardsButton>
      <Rewards show={showRewards}>
        <Cross
          onClick={() => {
            setShowRewards(false)
          }}
        />
        <RewardsAlbumName isChanging={isChanging}>
          Récompenses pour "{currentAlbum.name}"
        </RewardsAlbumName>
        <Artist isChanging={isChanging}>
          {currentAlbum.artist.toUpperCase()}
        </Artist>
        <RewardsContainer>
          {currentAlbum.rewards.map((reward) => (
            <Reward>{reward}</Reward>
          ))}
        </RewardsContainer>
      </Rewards>
    </Wrapper>
  )
}

export default Album
